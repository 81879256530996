import { AppContext } from "../../Context";
import { Center, Container, Text, Image } from "@mantine/core";
import { useContext } from "react";
import ImageGallery from "react-image-gallery";

let images = [];

for (let i = 1; i <= 8; i++) {
  images.push({
    original: "/assets/project/preview (" + i + ").jpg",
  });
}

function Images() {
  return (
    <ImageGallery
      items={images}
      showThumbnails={false}
      showFullscreenButton={false}
      showPlayButton={false}
      showBullets={true}
      autoPlay={true}
    />
  );
}

function DescriptionEng() {
  return (
    <>
      <br />
      <Text>
        "Drift" is an experimental exploration of three living objects during
        the{" "}
        <a
          href="https://mp.weixin.qq.com/s?__biz=MjM5MDk5Mjc5Mw==&mid=2247484626&idx=1&sn=925652a5a4b06439a1a7eacce8d4f5fa&chksm=a6bd142491ca9d32697fcadf04d04e5627e5f1b3eb64740da828a8dd14cee089dd83cc23448f&scene=21#wechat_redirect"
          target="_blank"
        >
          PAN Shanghai art residency.
        </a>
      </Text>
      <Text>
        The exploration starts with the subject of <b>"Purge"</b> as a central
        theme of the 2022 residency program. What objects and emotions would you
        like to leave behind in the complicated year of 2022? To purge them.
        There were no specific results or requirements for the outcome, only a
        couple of limits:
        <ul>
          <li>Exhibition date</li>
          <li>Only use recycled materials and objects</li>
        </ul>
      </Text>
      <Center>
        <Images />
      </Center>
      <br />
      <Text>
        We recycle non-physical objects such as ideas, memories, and concepts.
        Our goal is to raise awareness of the intangible things surrounding us
        and influence our lives more than physical goods or events. We use
        Augmented Reality (AR) to amplify and reveal this extra dimension to
        viewers. The content for the experience is a personal perspective on
        data consumerism, value, and the meaning of memories, as well as a
        reflection on group members' work (video, poetry, images). This content
        is deconstructed and spread into the space, allowing visitors to
        reconstruct and interpret it at their own pace and order. This amplifies
        the idea of the fragility and context dependency of the memories that we
        form.
      </Text>
      <br />
      <Text>
        Scan QR code or this link to see the{" "}
        <a
          href="https://mp.weixin.qq.com/s/4duF-8no6jsD90ISnj28mQ"
          target="_blank"
        >
          exhibition review:
        </a>
      </Text>
      <Center>
        <img src="/assets/PanSH.png" width={256} />
      </Center>
    </>
  );
}

function DescriptionCn() {
  return (
    <>
      <Text>
        “漂移”是在
        <a
          href="https://mp.weixin.qq.com/s?__biz=MjM5MDk5Mjc5Mw==&mid=2247484626&idx=1&sn=925652a5a4b06439a1a7eacce8d4f5fa&chksm=a6bd142491ca9d32697fcadf04d04e5627e5f1b3eb64740da828a8dd14cee089dd83cc23448f&scene=21#wechat_redirect"
          target="_blank"
        >
          盘上海艺术驻地
        </a>
        期间对三个活体的实验性探索。
      </Text>
      <Text>
        断舍离”作为2022年驻地项目的中心主题开启了本次探索之旅。哪些物件和情绪是你在即将跨越复杂的2022年时想要抛却的？那就去清理掉它们。本次驻地对产出结果没有具体的要求，只有几项规则：
        <ul>
          <li>展出日期</li>
          <li>仅使用可回收材料和物件</li>
        </ul>
      </Text>
      <Center>
        <Images />
      </Center>
      <br />
      <Text>
        我们回收无形物，如想法、记忆和概念。我们试图提升对身边无形物的重视，意识到它们比实物或事件更能影响我们的生活。我们使用增强现实（AR）来增强和展示这一额外维度给观众，体验内容是对数据消费主义、价值和记忆意义的个人视角，以及成员作品（影像、诗歌和图像）的映像。这些内容被解构并散播在空间中，让观众能够按照自己的节奏和顺序对其进行重构和解读，这放大了我们所形成记忆的脆弱性和依附于情景的观点。
      </Text>
      <br />
      <Text>
        扫描二维码或点击此链接查看
        <a
          href="https://mp.weixin.qq.com/s/4duF-8no6jsD90ISnj28mQ"
          target="_blank"
        >
          展览回顾:
        </a>
      </Text>
      <Center>
        <img src="/assets/PanSH.png" width={256} />
      </Center>
    </>
  );
}

export default function Description() {
  const appDetails = useContext(AppContext);

  return (
    <Container className="intro-container">
      {appDetails["lang"] == "en" ? <DescriptionEng /> : <DescriptionCn />}
    </Container>
  );
}
