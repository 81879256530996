import "./App.css";
import XRScene from "./component/AR/Scene";
import Intro from "./component/Pages/Intro";
import { useContext } from "react";
import { AppContext } from "./component/Context";
import Gallery from "./component/Pages/Gallery";
import Description from "./component/Pages/Description";
import LanguageMenu from "./component/UI/Language";
import Menu from "./component/UI/Menu";
import { Center, Title } from "@mantine/core";

function App() {
  const appDetails = useContext(AppContext);

  return (
    <div className="App">
      <div id="content">
        {appDetails["page"] === "home" && <Intro />}
        {appDetails["page"] === "ar" && (
          <div className="overflow-hidden">
            <XRScene />
          </div>
        )}
        {appDetails["page"] === "gallery" && <Gallery />}
        {appDetails["page"] === "info" && <Description />}
      </div>

      <div className="Header">
        <LanguageMenu />
        <Center>
          <Title order={2} className="main-title">
            {appDetails["lang"] == "en" ? "DRIFT" : "漂流"}
          </Title>
        </Center>

        <Menu />
      </div>
    </div>
  );
}

export default App;
