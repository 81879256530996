import { AppContext } from "../../Context";
import { Container, Text, Title, Button } from "@mantine/core";
import { useContext, useState } from "react";
import {
  randomFloatFromInterval,
  randomIntFromInterval,
} from "../../../utils/utils";
import ImageGallery from "react-image-gallery";

let images = [];
let imgs = 40;

for (let i = 0; i < imgs; i++) {
  const n = randomIntFromInterval(0, 84);
  images.push({
    original: "/assets/images/img (" + n + ").jpg",
  });
}

function Images() {
  return <ImageGallery items={images} autoPlay={true} />;
}

let preview = "https://src.vasily.onl/MemoryDrift.mp4";
if (randomFloatFromInterval(0, 1) > 0.5)
  preview = "https://src.vasily.onl/MemoryDrift2.mp4";

function GalleryEng() {
  const [works1, setWorks1] = useState(false);
  const [works2, setWorks2] = useState(false);
  const [works3, setWorks3] = useState(false);
  const [works4, setWorks4] = useState(false);

  return (
    <Container className="gallery">
      <blockquote>
        <i>
          What if we place 3 mirrors looking into each other? What memory this
          will leave for them?
        </i>
      </blockquote>

      {!works1 ? (
        <Button onClick={() => setWorks1(!works1)} className="bt-start">
          WATCH PREVIEW
        </Button>
      ) : (
        <video controls>
          <source src={preview} type="video/mp4" />
        </video>
      )}

      <br />
      <Title order={3}>Who took my private data?</Title>
      <Title order={5}>create: MOMO</Title>
      <Text>
        With the theory of Dataism, our memory only counts when we upload. How
        does the algorithm think when it saw our content? Do we really have free
        will? Is our life decide by our own wish, or determined by all kinds of
        influences?{" "}
      </Text>
      <br />
      {!works2 ? (
        <Button className="bt-start" onClick={() => setWorks2(!works2)}>
          FIGURE IT OUT
        </Button>
      ) : (
        <video controls>
          <source src="/assets/momo.mp4" type="video/mp4" />
        </video>
      )}

      <br />
      <Title order={3}>Memories of the era</Title>
      <Title order={5}>recall: Ringo</Title>
      <Text>
        After receiving Momo's work, the idea comes to me that "our memories can
        be deceptive, especially in the process of transmission, the value can
        be redefined". As a Spoken Word poet, I feel that everyone has the
        definition of their generation, and those important events and symbols
        constitute our "memory of The Times", so I think of the simile of
        "memory = weight". And in the moment of everything violently changes, we
        may feel ourselves small, born in a wrong time. In this poem I want to
        express, we can question the scale of history, only WE can define
        ourselves.
      </Text>
      <br />
      {!works3 ? (
        <Button className="bt-start" onClick={() => setWorks3(!works3)}>
          FEEL THE WEIGHT
        </Button>
      ) : (
        <audio controls src="/assets/RingoEn.mp3"></audio>
      )}

      <br />
      <Title order={3}>Fragile imagination</Title>
      <Title order={5}>remember: Vasily</Title>
      <Text>
        It is hard to imagine anything as fragile or temporary as memories while
        so essential for constructing our personality. We cannot tell which of
        our memories are real and which are created by our minds. Are they
        events from the past or primed by the media and environment?
      </Text>
      <Text>
        Every time I try to recall a memory, I reshape it. Every person I share
        it with becomes part of this memory. Why are we trying to preserve some
        memories while attempting to forget another?
      </Text>
      <Text>
        Here the visual flow of my memories, dreams, fiction, and memories
        injected into me by a machine mind.
      </Text>
      <br />
      {!works4 ? (
        <Button className="bt-start" onClick={() => setWorks4(!works4)}>
          DIVE IN
        </Button>
      ) : (
        <Images />
      )}
    </Container>
  );
}

function GalleryCn() {
  const [works1, setWorks1] = useState(false);
  const [works2, setWorks2] = useState(false);
  const [works3, setWorks3] = useState(false);
  const [works4, setWorks4] = useState(false);

  return (
    <Container className="gallery">
      <blockquote>
        <i>如果我们是三面对着彼此的镜子，我们会留下什么记忆？</i>
      </blockquote>
      {!works1 ? (
        <Button className="bt-start" onClick={() => setWorks1(!works1)}>
          观看预告
        </Button>
      ) : (
        <video controls>
          <source src={preview} type="video/mp4" />
        </video>
      )}
      <br />
      <Title order={3}>谁动了我的私人数据？</Title>
      <Title order={5}>create： MOMO</Title>
      <Text>
        数据主义之下，我们把自己的记忆交付给数码产品才会产生意义，算法本身看到我们的内容会作何感想呢？我们真的有自由意志吗？人生的一切安排是我们自己的主观决定，还是受各种影响而产生的呢？{" "}
      </Text>
      <br />
      {!works2 ? (
        <Button className="bt-start" onClick={() => setWorks2(!works2)}>
          看看结果
        </Button>
      ) : (
        <video controls>
          <source src="/assets/momo2.mp4" type="video/mp4" />
        </video>
      )}
      <br />
      <Title order={3}>时代的记忆</Title>
      <Title order={5}>recall： Ringo</Title>
      <Text>
        看到Momo的作品之后，我接收到的信息是，我们的记忆具有迷惑性，尤其是在记忆传递的过程中，它的价值被重新定义。作为一个Spoken
        Word口语诗人，我感受到每代人有每代人的话语，那些重要的事件和符号构成了我们的“时代记忆”，于是我想到了“记忆=砝码”这个意象。而在剧烈变动的当下，我们总是觉得自己渺小，生不逢时。我希望借由这首诗告诉大家，或许历史的天平并不平稳，不必伤春悲秋，我们就是自己的砝码。
      </Text>
      <br />
      {!works3 ? (
        <Button className="bt-start" onClick={() => setWorks3(!works3)}>
          体验砝码
        </Button>
      ) : (
        <audio controls src="/assets/RingoCn.mp3"></audio>
      )}
      <br />
      <Title order={3}>碎片化的想象</Title>
      <Title order={5}>remember: Vasily</Title>
      <Text>
        很难想象有什么东西像记忆一样脆弱或短暂，却是人格塑造过程中必不可少的一环。我们无法分辨哪些记忆是真实的，哪些是我们大脑虚构的，它们来自过去的事件抑或由媒体和环境引发？
      </Text>
      <Text>
        每当我试图回溯一段记忆时，我都会重塑它；每当我与一个人分享记忆，都变成这段记忆的一部分。为何我们总是试图留存一部分记忆而试图遗忘另一部分？
      </Text>
      <Text>
        这些视觉流来自我的记忆、梦境、幻想和被机器思维注入我脑海内的记忆。
      </Text>
      <br />
      {!works4 ? (
        <Button className="bt-start" onClick={() => setWorks4(!works4)}>
          看看结果
        </Button>
      ) : (
        <Images />
      )}
      <br />
    </Container>
  );
}

export default function Gallery() {
  const appDetails = useContext(AppContext);

  return (
    <Container className="intro-container">
      {appDetails["lang"] == "en" ? <GalleryEng /> : <GalleryCn />}
    </Container>
  );
}
