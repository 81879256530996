import { Container, Tooltip } from "@mantine/core";
import { useContext } from "react";

import {
  CameraIcon,
  ImageIcon,
  ReaderIcon,
  RocketIcon,
} from "@radix-ui/react-icons";
import { AppContext, AppDispatchContext } from "../../Context";

const tooltips = {
  en: {
    home: "INTRO",
    ar: "AR",
    gallery: "GALLERY",
    info: "PROJECT DESCRIPTION",
  },
  cn: {
    home: "介绍",
    ar: "AR",
    gallery: "画廊",
    info: "项目描述",
  },
};

export default function Menu() {
  const appDetails = useContext(AppContext);
  const setAppDetails = useContext(AppDispatchContext);

  function SetMenu(item) {
    if (appDetails["page"] != item) {
      setAppDetails({
        ...appDetails,
        page: item,
      });
    }
  }

  return (
    <Container className="menu">
      <Container
        className="menu-item"
        onClick={() => {
          SetMenu("home");
        }}
      >
        <Tooltip label={tooltips[appDetails["lang"]].home}>
          <RocketIcon className="svg-icon" onClick={null} />
        </Tooltip>
      </Container>
      <Container
        className="menu-item"
        onClick={() => {
          SetMenu("ar");
        }}
      >
        <Tooltip label={tooltips[appDetails["lang"]].ar}>
          <CameraIcon className="svg-icon" onClick={null} />
        </Tooltip>
      </Container>
      <Container
        className="menu-item"
        onClick={() => {
          SetMenu("gallery");
        }}
      >
        <Tooltip label={tooltips[appDetails["lang"]].gallery}>
          <ImageIcon className="svg-icon" onClick={null} />
        </Tooltip>
      </Container>
      <Container
        className="menu-item"
        onClick={() => {
          SetMenu("info");
        }}
      >
        <Tooltip label={tooltips[appDetails["lang"]].info}>
          <ReaderIcon className="svg-icon" onClick={null} />
        </Tooltip>
      </Container>
    </Container>
  );
}
