import { Title, Text, Container, List, Button, Center } from "@mantine/core";
import { useContext } from "react";
import { AppContext, AppDispatchContext } from "../../Context";
import ImageGallery from "react-image-gallery";

let images = [];

for (let i = 1; i <= 4; i++) {
  images.push({
    original: "/assets/project/dr_" + i + ".png",
  });
}

function Images() {
  return (
    <ImageGallery
      items={images}
      showThumbnails={false}
      showFullscreenButton={false}
      showPlayButton={false}
      showBullets={true}
      autoPlay={true}
    />
  );
}

export default function Intro() {
  const appDetails = useContext(AppContext);
  const setAppDetails = useContext(AppDispatchContext);

  function SetMenu(item) {
    if (appDetails["page"] != item) {
      setAppDetails({
        ...appDetails,
        page: item,
      });
    }
  }

  return (
    <Container className="intro-container">
      {appDetails["lang"] == "en" ? (
        <>
          <Center>
            <Images />
          </Center>
          <blockquote>
            <i>
              What memories would we leave if we were three mirrors facing each
              other?
            </i>
          </blockquote>
          <Text>
            This project is an exploration of the sources and evolution of
            memories. Created through a relay method, we allowed our ideas to
            drift from one medium to another.{" "}
          </Text>
          <List>
            <List.Item>
              I am <b>Momo</b>; use my brain maps as a starting point for your
              wanderings into my memories.{" "}
            </List.Item>
            <List.Item>
              I am <b>Ringo</b>; follow my poems and descend into the memories
              of eras.{" "}
            </List.Item>
            <List.Item>
              I am <b>Vasily</b>. Which memories are my past, my dream, the
              result of my imagination, or have been injected into me?
            </List.Item>
          </List>
          <br />
          <Title order={5}>INSTRUCTION</Title>
          <List>
            <List.Item>
              If you scan through WeChat, please press the top right corner
              button and select <i>"open in the browser.</i>"
            </List.Item>
            <List.Item>
              Best experience on Android devices. For IOS devices, you need to
              open it in the{" "}
              <a
                href="https://apps.apple.com/us/app/webxr-viewer/id1295998056"
                target="_blank"
              >
                WebXR viewer.
              </a>
            </List.Item>
            <List.Item>
              Please put on your headphones, and explore the area 4-5 meters
              around you.
            </List.Item>
            <List.Item>Be aware of the physical objects around you.</List.Item>
          </List>
          <br />
          <Text>
            You can watch a &nbsp;
            <a
              href="#"
              onClick={() => {
                SetMenu("gallery");
              }}
            >
              video preview
            </a>{" "}
            if you have difficulty viewing it on your phone.
          </Text>
          <br />
          <Button
            className="bt-start"
            onClick={() => {
              SetMenu("ar");
            }}
          >
            START DRIFTING
          </Button>
        </>
      ) : (
        <>
          <Center>
            <Images />
          </Center>
          <blockquote>
            <i>如果我们是三面对着彼此的镜子，我们会留下什么记忆？</i>
          </blockquote>
          <Text>
            这个项目是对记忆来源和演变的探索。通过中继方法创建，我们允许我们的想法从一种媒介转移到另一种媒介。
          </Text>
          <List>
            <List.Item>
              我是<b>Momo</b>；请从我的脑中图景开始漫游，进入我的回忆。
            </List.Item>
            <List.Item>
              我是<b>Ringo</b>；诗歌会带你坠入时代的回忆。
            </List.Item>
            <List.Item>
              我是<b>Vasily</b>
              。哪些记忆是我过去的，我的梦想，我想象的结果，还是被注入到我身体里的？
            </List.Item>
          </List>
          <br />
          <Title order={5}>说明</Title>
          <List>
            <List.Item>
              如果您在微信中浏览，请按右上角的按钮，然后选择
              <i>“在浏览器中打开”</i>。
            </List.Item>
            <List.Item>
              在 Android 设备上获得最佳体验。对于 IOS 设备，您需要在
              <a
                href="https://apps.apple.com/us/app/webxr-viewer/id1295998056"
                target="_blank"
              >
                WebXR viewer
              </a>
              查看器中打开它。
            </List.Item>
            <List.Item>请戴上耳机，探索周围 4-5 米的区域。</List.Item>
            <List.Item>注意周围的实物。</List.Item>
          </List>
          <Text>
            如果您在手机上看起来有困难，可以观看&nbsp;
            <a
              href="#"
              onClick={() => {
                SetMenu("gallery");
              }}
            >
              视频预览。
            </a>
          </Text>
          <br />
          <Button
            className="bt-start"
            onClick={() => {
              SetMenu("ar");
            }}
          >
            开始漂流
          </Button>
        </>
      )}
    </Container>
  );
}
